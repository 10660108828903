<template>
  <div class="home">
    <h1 class="d-sr-only">{{ title }}</h1>
    <p class="d-sr-only" v-text="description" />

    <div class="banner d-flex flex-column px-6 mx-auto">
      <v-row justify="center" class="container">
        <v-col cols="12" align="center" class="d-flex align-end justify-center">
          <h2 class="white--text title-text">
            {{ $t("homepage.title") }}
          </h2>
        </v-col>

        <v-col cols="12" sm="12" md="12" lg="10">
          <div v-if="!$vuetify.breakpoint.xs" class="w-100">
            <SearchInput />
          </div>
          <div v-else>
            <SearchMobile />
          </div>
          <div
            class="d-flex flex-row justify-space-between pt-8"
            v-if="!$vuetify.breakpoint.smAndDown"
          >
            <v-card
              class="d-flex flex-column primary--text card pa-3"
              :width="$vuetify.breakpoint.mdAndDown ? '160' : '200'"
              outlined
              v-for="(card, index) in cards"
              :key="index"
            >
              <h1
                v-if="card && card.value"
                :class="card.value.length < 6 ? 'card-h1' : 'card-h1-small'"
              >
                <v-icon x-large color="primary">{{ card.icon }}</v-icon>
                {{ card.value }}
              </h1>
              <p class="card-text pt-2 ma-0 grey--text text--darken-3">
                {{ $t(card.text) }}
              </p>
            </v-card>
          </div>
          <!-- <div v-if="!$vuetify.breakpoint.smAndDown" class="suggestions">
            {{ $t("homepage.whatNumbersMean") }}
            <span
              class="suggestion"
              @click.stop="$router.push('/profile/profile-update')"
              >{{ $t("homepage.goToProfile") }}</span
            >
          </div> -->
        </v-col>
      </v-row>
    </div>

    <div class="pt-4 container">
      <v-tabs centered v-model="tab" class="home-tab">
        <v-tabs-slider color="secondary lighten-1"></v-tabs-slider>
        <v-tab>{{ $t("navbar.navigation.home") }}</v-tab>
        <v-tab>{{ $t("navbar.navigation.browse") }}</v-tab>
        <!-- <v-tab>{{ $t("navbar.navigation.learnMore") }}</v-tab> -->
      </v-tabs>
      <v-tabs-items v-model="tab" touchless>
        <v-tab-item>
          <v-container fluid class="px-0 pt-8">
            <ProductListSlider
              :title="$t('homepage.productsTitle')"
              :subtitle="$t('homepage.productsSubTitle')"
              :key="key"
              position="home_footer_products"
            />
          </v-container>
        </v-tab-item>
        <v-tab-item>
          <v-container fluid class="px-0 pt-8 white">
            <CategoryHomeGrid
              :title="$t('homepage.categoryTitle')"
              :subtitle="$t('homepage.categorySubTitle')"
            />
          </v-container>
        </v-tab-item>
        <!-- <v-tab-item>
          <v-container fluid class="px-0 pt-8">
            <LearnMore
              :title="$t('homepage.learnMoreTitle')"
              :subtitle="$t('homepage.learnMoreSubTitle')"
            />
          </v-container>
        </v-tab-item> -->
      </v-tabs-items>
    </div>
    <!-- <div v-else>
      <v-container fluid v-for="proposal in footerProducts" :key="proposal.id">
        <ProductListSlider
          :proposal="proposal"
          :title="$t('homepage.productsTitle')"
          :subtitle="$t('homepage.productsSubTitle')"
          :paginationClass="'swiper-pagination' + proposal.id"
          :key="key"
          position="home_footer_products"
        />
      </v-container>
    </div> -->
    <div class="primary lighten-2 py-12">
      <SearchGrid
        :title="$t('homepage.lastSearchedTitle')"
        :subtitle="$t('homepage.lastSearchedSubTitle')"
      />
    </div>
  </div>
</template>
<script>
import ProductListSlider from "@/components/product/ProductListSlider.vue";
import CategoryHomeGrid from "@/components/category/CategoryHomeGrid.vue";
import category from "~/mixins/category";
import login from "~/mixins/login";
import deliveryReactive from "~/mixins/deliveryReactive";
import SearchInput from "@/components/navigation/SearchInput.vue";
import SearchMobile from "@/components/navigation/SearchMobile.vue";
import SearchGrid from "@/components/navigation/SearchGrid.vue";
//import LearnMore from "@/components/wordpress/LearnMore.vue";

import { mapProposal } from "~/service/ebsn.js";
import { mapActions, mapGetters, mapState } from "vuex";

export default {
  name: "Home",
  mixins: [category, deliveryReactive, login],
  components: {
    ProductListSlider,
    CategoryHomeGrid,
    SearchInput,
    SearchMobile,
    SearchGrid
    //LearnMore
  },
  data() {
    return {
      searchValue: null,
      tab: null,
      slides: {},
      key: 1
    };
  },
  methods: {
    ...mapActions({
      resetFilters: "category/resetFilters"
    }),
    async reload() {
      let slug = this._.split(this.category.slug, "@")[0];
      await this.resetFilters(slug);
      await this.setCategory({ slug: slug, force: true });
      this.key += 1;
    }
  },
  computed: {
    ...mapState({
      categories: ({ category }) => category.categoryTree
    }),
    ...mapGetters({
      hasFavorites: "cart/hasFavorites",
      isAuthenticated: "cart/isAuthenticated",
      commonSearch: "wordsSearched/commonSearch",
      productsCount: "category/productsCount",
      categoriesCount: "category/categoriesCount",
      brandsCount: "category/brandsCount",
      companyCount: "category/companyCount"
    }),
    ...mapProposal({
      carousel: "carousel",
      sideboxBanner: "sidebox-banner",
      sideboxBannerLogged: "sidebox-banner-logged",
      headerProducts: "header-products",
      headerBanner: "header-banner",
      footerBanner: "footer-banner",
      footerProducts: "footer-products",
      industryBanner: "industry-banner"
    }),
    cards() {
      return [
        {
          text: "navbar.products",
          value: this.isAuthenticated ? this.productsCount : "--",
          icon: "mdi-basket"
        },
        {
          text: "product.details.companies",
          value: this.isAuthenticated ? this.companyCount : "--",
          icon: "mdi-tools"
        },
        {
          text: "advanceSearch.brand",
          value: this.isAuthenticated ? this.brandsCount : "--",
          icon: "mdi-trademark"
        },
        {
          text: "common.categories",
          value: this.isAuthenticated ? this.categoriesCount : "--",
          icon: "mdi-message-text"
        }
      ];
    },
    title() {
      return this._.get(
        this.category,
        "metaData.category_info.TITLE",
        this.category.name
      );
    },
    description() {
      return this._.get(this.category, "metaData.category_info.DESCRIPTION");
    }
  },
  async mounted() {
    if (!this.isAuthenticated && this.$route.path == "/login-tech") {
      this.techLogin();
    }
    if (
      this.isAuthenticated &&
      this.$route.query.selectCompany &&
      this.$route.query.selectCompany == "true"
    ) {
      this.setCompany();
    }
  },
  watch: {
    $route(to) {
      if (!this.isAuthenticated && this.$route.path == "/login-tech") {
        this.techLogin();
      }
      if (to.query.tab) {
        this.tab = parseInt(to.query.tab);
        console.log(this.tab);
      }
    }
  }
};
</script>
<style scoped lang="scss">
.title {
  font-size: 2rem !important;
}
.titleSm {
  font-size: 1.75rem !important;
}
.bgimg {
  background-image: url("../assets/homepage/banner-example.jpg");
  background-size: cover;
}
.subTitle {
  font-size: 1.5rem !important;
}
</style>
