<template>
  <v-container class="mx-auto">
    <div class="d-flex justify-space-between align-center">
      <div
        class="d-flex flex-column justify-space-between align-start pb-4 section-title"
      >
        <h1 v-html="title" v-if="title" class="primary--text"></h1>
        <h3 v-html="subtitle" v-if="subtitle" class="secondary--text"></h3>
      </div>
      <div v-if="!$vuetify.breakpoint.xs">
        <v-btn
          class="nav-btn"
          text
          color="link"
          @click.prevent.stop="deleteAllWords"
          >{{ $t("homepage.clearAllWords") }} X</v-btn
        >
      </div>
    </div>
    <v-row v-if="commonSearch.length > 0">
      <v-col
        v-for="search in previouslySearched"
        :key="search.id"
        class="d-flex justify-center"
        cols="12"
        sm="6"
        md="4"
        lg="3"
      >
        <v-hover v-slot="{ hover }">
          <v-card
            rounded="sm"
            outlined
            class="linkToLists"
            height="50"
            :width="$vuetify.breakpoint.xs ? '100%' : 250"
            :class="{ 'on-hover': hover }"
            v-if="search.name"
          >
            <v-card-text
              class="d-flex justify-space-between align-center primary--text search-card h-100"
              @click="searchItem(search.name)"
              v-ripple
            >
              <div
                class="d-flex flex-column justify-space-around align-start"
                :class="`${hover ? 'text-white' : 'text-primary'}`"
                style="height:100%"
              >
                <h2 class="hide-overflow">{{ search.name }}</h2>
              </div>
              <v-icon :class="`${hover ? 'text-white' : 'text-primary'}`"
                >mdi-magnify</v-icon
              >
            </v-card-text>

            <v-spacer />
          </v-card>
        </v-hover>
      </v-col>
    </v-row>
    <div v-if="$vuetify.breakpoint.xs" class="pt-3">
      <v-btn
        class="nav-btn"
        text
        block
        color="link"
        @click.prevent.stop="deleteAllWords"
        >{{ $t("homepage.clearAllWords") }} X</v-btn
      >
    </div>
  </v-container>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "SearchGrid",
  props: {
    title: { type: String, required: false },
    subtitle: { type: String, required: false }
  },
  components: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      commonSearch: "wordsSearched/commonSearch"
    }),
    previouslySearched() {
      return this.commonSearch.filter(item => {
        if (item.name) {
          return item;
        }
      });
    }
  },
  methods: {
    searchItem(item) {
      let currentQuery = { q: item };
      this.$router.push({
        path: "/search",
        name: "Search",
        query: currentQuery
      });
    },
    ...mapActions({
      deleteAllWords: "wordsSearched/deleteAllWords"
    })
  }
};
</script>
<style scoped lang="scss">
.linkToLists {
  &:hover {
    cursor: pointer;
  }
}
.v-card {
  background-color: $primary;
  border-radius: 0.25rem !important;
}
.v-card:not(.on-hover) {
  background-color: white;
  border-radius: 0.25rem !important;
}
.text-white {
  color: white;
  font-weight: 400 !important;
}
.text-primary {
  color: $primary !important;
  font-weight: 400 !important;
}
.hide-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
</style>
