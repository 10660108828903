<template>
  <v-card
    outlined
    rounded="md"
    :to="{ name: 'Product', params: { slug: product.slug } }"
  >
    <v-flex class="flex-column">
      <v-row no-gutters justify="center" class="py-3">
        <v-col justify="center" cols="12" class="d-flex align-center">
          <v-img
            :src="product.mediaURL"
            contain
            :width="170"
            :height="200"
            :alt="'Immagine' + product.name"
            :title="
              product.name + ' (' + product.codInt + '-' + product.codVar + ')'
            "
          >
            <!-- icona surgelato -->
            <!-- <v-tooltip left v-if="product.productInfos.IS_SURGELATO == 'true'">
              <template v-slot:activator="{ on, attrs }">
                <div class="d-flex justify-end pr-16" v-bind="attrs" v-on="on">
                  <img
                    aria-hidden
                    alt="Immagine surgelato"
                    src="/product-info/surgelato.svg"
                  />
                </div>
              </template>
              <span>{{ $t("product.frozen") }}</span>
            </v-tooltip> -->
          </v-img>
        </v-col>
        <v-col
          no-gutters
          justify="center"
          align="center"
          cols="12"
          class="d-flex flex-column text-left product-info"
        >
          <!-- <v-col
            cols="12"
            class="d-flex flex-column text-left product-info py-3"
          > -->
          <div class="label px-3">
            {{ $t("advanceSearch.brand") }}
          </div>
          <div
            class="info-title px-3"
            style="min-height: 25px;"
            v-html="product.shortDescr"
          ></div>
          <div class="label px-3">
            {{ $t("product.details.description") }}
          </div>
          <div
            class="info-details px-3"
            style="min-height: 72px;"
            v-html="product.name"
          ></div>
          <!-- </v-col> -->
        </v-col>
      </v-row>
      <!-- <v-row no-gutters justify="center">
        <v-col cols="12" class="d-flex flex-column text-left">
          <div class="secondary--text px-3">
            AZIENDA
          </div>
          <div class="primary--text px-3 pb-3" style="min-height: 25px;">
            <h3>{{ product.shortDescr }}</h3>
          </div>
          <div class="secondary--text px-3">
            DESCRIZIONE
          </div>
          <div
            class="grey--text text--darken-3 px-3 text-capitalize"
            style="min-height: 60px;"
          >
            {{ description }}
          </div>
        </v-col>
      </v-row> -->
      <div
        class="d-flex flex-row justify-end px-3 pb-1"
        v-if="product.categorySlug"
      >
        <v-btn
          color="link"
          text
          class="text-capitalize nav-btn"
          :to="{
            name: 'Category',
            params: { pathMatch: product.categorySlug }
          }"
          >{{ $t("navbar.viewInCategory") }}
          <v-icon right>
            mdi-arrow-right
          </v-icon></v-btn
        >
      </div>
    </v-flex>
  </v-card>
</template>

<script>
import productMixin from "~/mixins/product";

import analyticsService from "~/service/analyticsService";
import CategoryService from "~/service/categoryService";

import { mapState, mapGetters } from "vuex";
import { formatProductCode } from "@/service/marketsuiteUtils";

export default {
  name: "ProductSwiperCard",
  props: ["product", "position"],
  components: {},
  data() {
    return {
      productCategory: {}
    };
  },
  mixins: [productMixin],
  computed: {
    ...mapGetters({
      getItemByProductId: "cart/getItemByProductId",
      isAuthenticated: "cart/isAuthenticated"
    }),
    ...mapState({
      cart: ({ cart }) => cart.cart
    }),
    item() {
      return null;
    },
    productCode() {
      return formatProductCode(this.product);
    },
    description() {
      return (
        this.product.shortDescr +
        " - " +
        this.product.name +
        " " +
        this.product.description
      );
    }
  },
  methods: {
    logClickProduct() {
      analyticsService.clickProduct(this.product, this.position);
    },
    async fetchCategory() {
      try {
        this.productCategory = await CategoryService.getCategoryById(
          this.product.categoryId
        );
      } catch (e) {
        console.log(e);
      }
    }
  }
  // async mounted() {
  //   await this.fetchCategory();
  // }
};
</script>
<style scoped lang="scss">
.info-details {
  -webkit-line-clamp: 3;
  line-clamp: 3;
}
.v-card {
  border: 1px solid #e5f0fc;
  border-radius: 0.25rem !important;
  -webkit-box-shadow: 0px 2px 3px 0px rgb(0 44 108 / 20%);
  box-shadow: 0px 2px 3px 0px rgb(0 44 108 / 20%);
}
</style>
