<template>
  <v-container class="mx-auto">
    <div class="section-title">
      <h1 class="primary--text">
        {{ $t("homepage.categoryTitle") }}
      </h1>
      <h3 class="secondary--text">
        {{ $t("homepage.categorySubTitle") }}
      </h3>
    </div>
    <div
      class="d-flex justify-end align-center"
      v-if="$vuetify.breakpoint.smAndUp"
    >
      <div>
        <v-select
          :items="sort"
          item-text="text"
          item-value="value"
          :value="sortFilter"
          label="Ordina per"
          @change="handleSortFilter"
        ></v-select>
      </div>
    </div>
    <div
      class="d-flex justify-space-between align-center primary lighten-2 mx-n3"
      v-else
    >
      <div class="pl-3">{{ $t("sort") }}</div>
      <v-btn-toggle
        :value="sortFilter"
        group
        color="primary"
        @change="handleSortFilter"
      >
        <v-btn class="round" value="alf_asc">
          <v-icon>mdi-sort-alphabetical-ascending</v-icon>
        </v-btn>

        <v-btn class="round" value="alf_dsc">
          <v-icon>mdi-sort-alphabetical-descending</v-icon>
        </v-btn>
      </v-btn-toggle>
    </div>
    <v-row v-if="$vuetify.breakpoint.smAndUp">
      <v-col
        v-for="category in currentcategory"
        :key="category.id"
        class="d-flex justify-center"
        cols="12"
        sm="6"
        md="4"
        lg="3"
      >
        <v-hover v-slot="{ hover }">
          <v-card
            rounded="sm"
            flat
            elevation="0"
            width="250"
            height="90"
            class="category-card"
            :class="{ 'on-hover': hover }"
            @click.prevent="handleCategory(category)"
          >
            <v-card-text
              class="d-flex justify-space-between align-center h-100"
            >
              <div
                class="d-flex flex-column justify-space-around align-start pl-2 card-text"
                :class="`${hover ? 'text-white' : 'text-primary'}`"
                style="height:100%"
              >
                <h2>{{ category.name }}</h2>
                <div
                  class="link--text"
                  @click.stop.prevent="handleAllProductsLink(category)"
                >
                  Vedi tutti prodotti
                </div>
              </div>
              <div>
                <v-icon
                  large
                  :class="`${hover ? 'text-white' : 'text-primary'}`"
                  >mdi-chevron-right</v-icon
                >
              </div>
            </v-card-text>
          </v-card>
        </v-hover>
      </v-col>
    </v-row>
    <v-list v-else>
      <v-list-item
        v-for="category in currentcategory"
        :key="category.id"
        @click.prevent="handleCategory(category)"
      >
        <v-list-item-title>{{ category.name }}</v-list-item-title>
        <v-list-item-action>
          <v-icon color="primary">mdi-chevron-right</v-icon>
        </v-list-item-action>
      </v-list-item>
    </v-list>
  </v-container>
</template>
<script>
//import CategoryCard from "./CategoryCard.vue";
import category from "~/mixins/category";
import CategoryService from "~/service/categoryService";
import { mapState, mapGetters } from "vuex";
export default {
  name: "CategoryHomemGrid",
  mixins: [category],
  //components: { CategoryCard },
  data() {
    return {
      select: null,
      sort: [
        { value: "alf_asc", text: "A-Z" },
        { value: "alf_dsc", text: "Z-A" }
      ],
      currentcategory: []
    };
  },
  methods: {
    handleSortFilter(sort) {
      //this.$store.dispatch("category/filterCategoryTree", sort);
      const categories = this.currentcategory.slice();
      if (sort === "alf_asc") {
        categories.sort(function(a, b) {
          var textA = a.name.toUpperCase();
          var textB = b.name.toUpperCase();
          return textA < textB ? -1 : textA > textB ? 1 : 0;
        });
      } else {
        categories.sort(function(a, b) {
          var textA = a.name.toUpperCase();
          var textB = b.name.toUpperCase();
          return textA > textB ? -1 : textA < textB ? 1 : 0;
        });
      }
      this.currentcategory = categories;
    },
    handleLink(url) {
      if (url) {
        this.$router.push({
          name: "Category",
          params: { pathMatch: url }
        });
      }
    },
    async handleCategory(category) {
      let c = await CategoryService.getCategoryBySlug(category.slug);
      if (c.categoryTypeId == "1" && c.children) {
        c.children = c.children.filter(el => {
          return this.allCategories.some(f => {
            return f.categoryId === el.categoryId;
          });
        });
      }
      if (c.children && c.children.length > 0) {
        this.currentcategory = c.children;
      } else {
        this.$router.push({
          name: "Category",
          params: { pathMatch: category.slug }
        });
      }
    },
    handleAllProductsLink(category) {
      this.$router.push({
        name: "Category",
        query: { allProducts: true },
        params: { pathMatch: category.slug, type: "Products" }
      });
    }
  },
  computed: {
    ...mapState({
      categories: ({ category }) => category.categoryTree,
      allCategories: ({ category }) => category.categoryList
    }),
    ...mapGetters({
      sortFilter: "category/sortCategoryFilter"
    })
  },
  metaInfo() {
    return {
      title: this.$t("navbar.navigation.browse")
    };
  },
  mounted() {
    this.currentcategory = this.categories;
  }
};
</script>
<style scoped lang="scss">
.round {
  border-radius: 50% !important;
}
.linkToLists {
  &:hover {
    cursor: pointer;
  }
}
.v-card {
  background-color: $primary;
  border-radius: 0.25rem !important;
}
.v-card:not(.on-hover) {
  background-color: white;
  border-radius: 0.25rem !important;
  border: 1px solid var(--v-primary-base);
}
.v-card__text {
  padding: 10px;
}
.text-white {
  color: white;
}
.text-primary {
  color: $primary !important;
}
.v-card--link:before {
  background: none;
}
.link--text {
  &:hover {
    color: white !important;
  }
}
.card-text {
  width: 88%;
  h2 {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}
</style>
