<template>
  <v-combobox
    v-model="selected"
    ref="autocomplete"
    :items="items"
    :append-icon="appendIcon"
    :menu-props="{ closeOnContentClick: true }"
    :search-input.sync="search"
    :label="$t('navbar.searchCategory')"
    :placeholder="$t('navbar.searchCategory')"
    auto-select-first
    background-color="white"
    flat
    outlined
    item-text="name"
    item-value="name"
    clearable
    solo
    dense
    hide-details
    hide-no-data
    return-object
    type="search"
    class="pt-3"
    @click:append="doSearch"
    @keydown.enter.native.prevent.stop="doSearch"
  >
    <template v-slot:item="{ item }">
      <v-list-item-content @click="doSearch(item)">
        <v-list-item-title>
          {{ item.name }}
        </v-list-item-title>
      </v-list-item-content>
    </template>
  </v-combobox>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "SearchCategory",
  props: {
    focusOnOpen: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      required: false,
      default: "navbar.searchPlaceholder"
    },
    appendIcon: {
      type: String,
      required: false,
      default: "mdi-file-tree-outline"
    }
  },
  data() {
    return {
      loading: false,
      isFocused: null,
      selected: null,
      search: null,
      items: [],
      flatTree: []
    };
  },
  computed: {
    ...mapState({
      categories: ({ category }) => category.categoryTree,
      allCategories: ({ category }) => category.categoryList
    })
  },
  watch: {
    search(val, oldVal) {
      if (val !== oldVal && val && val.length > 0) {
        this.fetchSuggestion();
      }
    }
  },
  methods: {
    doSearch(item) {
      if (item && item.slug) {
        this.$router.push({
          name: "Category",
          params: { pathMatch: item.slug },
          query: { allProducts: true }
        });
      }
    },
    fetchSuggestion() {
      this.items = this.allCategories;
    }
  }
};
</script>
<style>
.v-select.v-select--is-menu-active .v-input__icon--append .v-icon {
  transform: none;
}
</style>
