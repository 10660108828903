<template>
  <v-container class="mx-auto">
    <div
      class="d-flex justify-space-between align-center flex-wrap header-full"
    >
      <div
        class="d-flex flex-column justify-space-between align-start pb-4 section-title"
      >
        <h1 v-html="title" v-if="title" class="primary--text"></h1>
        <h3 v-html="subtitle" v-if="subtitle" class="secondary--text"></h3>
      </div>
      <div>
        <v-btn
          class="nav-btn"
          text
          color="link"
          v-if="products"
          :to="{
            path: '/search',
            name: 'Search',
            query: { ...query, sort: 'versionDate_desc' }
          }"
          >{{ $t("homepage.viewAllProducts") }}
          <v-icon small>mdi-arrow-right</v-icon></v-btn
        >
      </div>
    </div>
    <v-container v-intersect.once="handleView" class="mx-auto" v-if="products">
      <swiper
        :options="swiperOption"
        ref="swiperRef"
        v-if="products.length > 0"
        class="swiper pb-6"
      >
        <swiper-slide v-for="product in products" :key="product.productId">
          <ProductSwiperCard v-bind:product="product" :position="position" />
        </swiper-slide>
        <div class="swiper-button-next" slot="button-next">
          <v-btn class="mr-3 white" elevation="3" x-large icon
            ><v-icon>mdi-arrow-right-thin</v-icon></v-btn
          >
        </div>
        <div class="swiper-button-prev" slot="button-prev">
          <v-btn class="ml-3 white" elevation="3" x-large icon
            ><v-icon>mdi-arrow-left-thin</v-icon></v-btn
          >
        </div>
        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>
    </v-container>
  </v-container>
</template>

<script>
import ProductService from "~/service/productService";
import ProductSwiperCard from "@/components/product/ProductSwiperCard";

import analyticsService from "~/service/analyticsService";

export default {
  name: "ProductListSlider",
  props: {
    categoryId: { type: Number, required: false },
    productId: { type: Number, required: false },
    layout: { type: Number, required: false },
    shuffle: { type: Boolean, required: false, default: false },
    promo: { type: Boolean, required: false, default: false },
    limit: { type: Number, required: false, default: 8 },
    title: { type: String, required: false },
    subtitle: { type: String, required: false },
    proposal: { type: Object, required: false },
    position: { type: String, required: false }
  },
  components: { ProductSwiperCard },
  data() {
    return {
      products: [],
      query: null,
      paginationClass: "'swiper-pagination'",
      swiperOption: {
        slidesPerView: 1,
        slidesPerGroup: 1,
        spaceBetween: 16,
        watchOverflow: true,
        pagination: {
          el: ".swiper-pagination",
          clickable: true
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev"
        },
        breakpoints: {
          300: {
            slidesPerView: 1.3,
            slidesPerGroup: 1,
            centeredSlides: true
          },
          400: {
            slidesPerView: 1.5,
            slidesPerGroup: 1,
            centeredSlides: true
          },
          600: {
            slidesPerView: 1.5,
            slidesPerGroup: 1,
            centeredSlides: false
          },
          700: {
            slidesPerView: 2,
            slidesPerGroup: 2,
            centeredSlides: false
          },
          960: {
            slidesPerView: 3,
            slidesPerGroup: 3,
            centeredSlides: false
          },
          1280: {
            slidesPerView: 4,
            slidesPerGroup: 4,
            centeredSlides: false
          }
        }
      }
    };
  },
  async mounted() {
    await this.fetchProducts();
  },
  computed: {
    mode() {
      return this._.get(
        this.proposal,
        "metaData.category_proposal_type.MODE",
        "card"
      );
    }
  },
  methods: {
    async fetchProducts() {
      this.getDates();
      let response = await ProductService.search({
        ...this.query,
        page_size: 10,
        web_enabled: true,
        sort: "versionDate_desc"
      });
      this.products = response.products;
    },
    handleView(entries, observer, isIntersecting) {
      if (isIntersecting && this.products.length > 0) {
        analyticsService.viewProducts(this.products, this.position);
      }
    },
    formatDate(date) {
      var dd = String(date.getDate()).padStart(2, "0");
      var mm = String(date.getMonth() + 1).padStart(2, "0");
      var yyyy = date.getFullYear();

      return yyyy + "-" + mm + "-" + dd;
    },
    getDates() {
      var today = this.formatDate(new Date());
      var fivedaysAgo = this.formatDate(
        new Date(new Date().setDate(new Date().getDate() - 5))
      );
      this.query = { version_date_from: fivedaysAgo, version_date_to: today };
    }
  }
};
</script>
<style scoped lang="scss">
.swiper-button-next:after,
.swiper-container-rtl .swiper-button-prev:after {
  content: none !important;
}
.swiper-button-prev:after,
.swiper-container-rtl .swiper-button-next:after {
  content: none !important;
}
.swiper {
  overflow: visible;
  &::v-deep .swiper-pagination {
    bottom: -25px;
  }
  &::v-deep .swiper-pagination-bullet {
    background-color: #696d73;
    width: 30px;
    height: 4px;
    border-radius: 0%;
    opacity: 1;
  }
  &::v-deep .swiper-pagination-bullet-active {
    background-color: var(--v-secondary-base);
  }
}
.swiper-button-next {
  right: -22px;
}
.swiper-button-prev {
  left: -22px;
}
.header-full {
  margin-left: -15px;
  margin-right: -30px;
}
</style>
