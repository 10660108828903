<template>
  <div class="d-flex flex-column justify-space-between pa-3 white">
    <v-text-field
      v-model="word"
      :label="$t('navbar.searchPlaceholder')"
      single-line
      outlined
      dense
      @keydown.enter.native.prevent.stop="doSearch"
    ></v-text-field>
    <SearchCategory v-if="$vuetify.breakpoint.xs" />
    <div class="pt-6">
      <v-btn block color="primary" @click="doSearch">
        {{ $t("navbar.searchSite") }}
      </v-btn>
    </div>
  </div>
</template>
<script>
import SearchCategory from "@/components/navigation/SearchCategory.vue";
import { mapActions } from "vuex";
export default {
  name: "SearchMobile",
  components: {
    SearchCategory
  },
  data() {
    return {
      word: null
    };
  },
  methods: {
    ...mapActions({
      saveWord: "wordsSearched/saveWord"
    }),
    doSearch() {
      this.items = [];
      var currentQuery = {};
      if (this.word && this.word.length > 0) {
        if (!isNaN(this.word)) {
          currentQuery.product_codes = this.word;
        } else {
          currentQuery.q = this.word;
          this.saveWord(currentQuery.q);
        }
      }

      if (currentQuery.q || currentQuery.product_codes) {
        this.$store.dispatch("category/resetFilters");
        this.$router.push({
          path: "/search",
          name: "Search",
          query: currentQuery
        });

        this.$nextTick(() => {
          this.word = currentQuery.q;
        });
      }
    }
  }
};
</script>
