<template>
  <!-- <v-row no-gutters>
    <v-col cols="8"> -->
  <div>
    <v-combobox
      v-model="selected"
      ref="autocomplete"
      :items="getItems"
      :search-input.sync="search"
      :label="$t(placeholder)"
      :placeholder="$t(placeholder)"
      :append-icon="appendIcon"
      :menu-props="{
        closeOnContentClick: true,
        maxHeight: 700
      }"
      no-filter
      background-color="white"
      flat
      item-text="name"
      item-value="name"
      clearable
      solo
      autofocus
      hide-details
      :hide-no-data="noData"
      return-object
      type="search"
      @click:append="doSearch"
      @focus="onFocus"
      @blur="onBlur"
      @keydown.enter.native.prevent.stop="doSearch"
    >
      <template v-slot:no-data>
        <div class="search-item">
          <h3 class="mt-0">{{ $t("searchPanel.recentSearches") }}</h3>
          <v-chip-group column>
            <v-chip
              v-for="(item, index) in previouslySearched"
              :key="index"
              dark
              label
              color="secondary"
              class="mr-3"
              @click="doSearch(item)"
            >
              {{ item.name }}
            </v-chip>
          </v-chip-group>
          <!-- <h3>{{ $t("searchPanel.collections") }}</h3> -->
          <!-- <div class="d-flex flex-wrap flex-row">
            <div v-for="(collection, index) in collections" :key="index">
              <CollectionCard :collection="collection" />
            </div>
          </div> -->
          <!-- <h3>{{ $t("searchPanel.trend") }}</h3> -->
          <!-- <div class="trends">
            <v-list class="white py-0" flat dense>
              <v-list-item
                v-for="(trend, index) in trends"
                :key="index"
                class="px-0"
              >
                <v-list-item-content>
                  <v-list-item-title>
                    {{ trend.name
                    }}<v-icon small color="link">mdi-arrow-right</v-icon>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </div> -->
        </div>
      </template>
      <template v-slot:item="{ item }" class="search-item">
        <v-list-item @click="doSearch(item)">
          <v-list-item-icon class="search-icon">
            <img v-if="item.type == 'Categorie'" :src="getImage(item)" />
            <v-icon
              v-else-if="item.type == 'Ricerche'"
              @click.prevent.stop="deleteWord(item)"
              small
            >
              mdi-close
            </v-icon>
            <v-icon v-else small>mdi-magnify</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              <text-highlight
                :queries="search ? search : '*'"
                class="search-highlight"
              >
                {{ item.name }}
              </text-highlight>
            </v-list-item-title>
            <v-list-item-subtitle v-if="item.type == 'Categorie'">
              Cerca <strong>{{ search }}</strong> in {{ item.name }}
            </v-list-item-subtitle>
            <v-list-item-subtitle v-if="item.type == 'Marche'">
              Cerca <strong>{{ search }}</strong> con marca {{ item.name }}
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <div class="badge" v-if="item.count">
              {{ item.count }}
            </div>
            <v-icon
              v-if="item.type == 'Ricerche'"
              @click.prevent.stop="search = item.name"
              small
            >
              mdi-arrow-top-left
            </v-icon>
          </v-list-item-action>
        </v-list-item>
      </template>
    </v-combobox>
    <div class="suggestions" v-if="suggestions.length > 0">
      {{ $t("common.suggestions") }}:
      <span
        class="suggestion"
        v-for="(suggestion, index) in suggestions"
        :key="index"
        @click="doSearch(suggestion)"
      >
        {{ suggestion.name }}
      </span>
    </div>
  </div>
  <!-- </v-col>
    <v-col cols="auto" class="d-flex flex-row"> -->
  <!-- <Barcode class="mx-1" v-if="$platform_is_cordova" @input="doSearch" />
      <v-btn
        class="mx-1"
        elevation="0"
        @click="openFastSearchDialog"
        color="secondary"
      >
        <v-icon color="white">mdi-text-search</v-icon>
        <span v-if="$vuetify.breakpoint.xl">{{ $t("navbar.fastSearch") }}</span>
      </v-btn> -->
  <!-- </v-col>
  </v-row> -->
</template>

<script>
import ProductService from "~/service/productService";
import AnalyticsService from "~/service/analyticsService";
import Vue from "vue";
import { mapGetters, mapActions } from "vuex";
import FastSearchMenu from "./FastSearchMenu";
//import CollectionCard from "@/components/category/CollectionCard.vue";
var vm;
export default {
  // components: { CollectionCard },
  props: {
    focusOnOpen: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      required: false,
      default: "navbar.searchPlaceholder"
    },
    appendIcon: {
      type: String,
      required: false,
      default: ""
    }
  },
  data() {
    return {
      //mocks
      collections: [
        { name: "Pasta ripiena, gnocchi", count: 64 },
        { name: "Pasta di semola", count: 24 },
        { name: "Pasta all'uovo", count: 36 },
        { name: "Pasta di semola insaporita", count: 56 }
      ],
      trends: [{ name: "Prodotti biologici" }, { name: "Coca cola zero " }],

      loading: false,
      isFocused: null,
      selected: null,
      search: null,
      items: [],
      menuProps: {
        closeOnContentClick: true,
        openOnClick: true
      }
    };
  },
  watch: {
    search(val, oldVal) {
      if (val !== oldVal && val && val.length > 1) {
        this.fetchSuggestion();
      }
    }
  },
  computed: {
    ...mapGetters({
      commonSearch: "wordsSearched/commonSearch",
      isAuthenticated: "cart/isAuthenticated"
    }),
    getItems() {
      return this.search ? this.items : [];
      // return this.items;
    },
    noData() {
      console.log(this.search ? true : false);
      return this.search ? true : false;
    },
    previouslySearched() {
      return this.commonSearch.filter(item => {
        if (item.name) {
          return item;
        }
      });
    },
    suggestions() {
      return this.previouslySearched.slice(0, 3);
    }
  },
  methods: {
    ...mapActions({
      saveWord: "wordsSearched/saveWord",
      deleteWord: "wordsSearched/deleteWord"
    }),
    getImage(item) {
      return "/search-category/" + item.id + ".png";
    },
    onFocus() {
      this.isFocused = true;
    },
    onBlur() {
      this.isFocused = false;
    },
    fetchSuggestion: Vue._.debounce(async function() {
      this.loading = true;
      if (this.search) {
        await ProductService.suggest(this.search.trim()).then(function(
          response
        ) {
          let newItems = [];
          let prevType = "none";

          global.vm.lodash.forEach(response, function(value) {
            if (value.type != prevType) {
              newItems.push({ header: value.type });
              prevType = value.type;
            }
            newItems.push(value);
          });
          vm.items = newItems;
          vm.loading = false;
        });
      }
    }, 200),
    doSearch(item) {
      this.items = [];
      this.$emit("closeSearchModal", true);
      var currentQuery = {};
      console.log(item);
      if (item.type === "Suggerimenti") {
        //ho selezionato un nome
        currentQuery.q = item.name;
        this.saveWord(currentQuery.q);
      } else if (item.type === "Categorie") {
        currentQuery.parent_category_id = item.id;
        currentQuery.filter = item.name;
        currentQuery.q = this.search;
      } else if (item.type === "Ricerche") {
        currentQuery.q = item.name;
      } else if (item.type === "Marche") {
        currentQuery.parent_vendor_id = item.id;
        currentQuery.filter = item.name;
        currentQuery.q = this.search;
      } else if (item.type === "Barcode") {
        currentQuery.barcode = item.name;
      } else {
        if (!this.isAuthenticated && this.search === "tech-login") {
          this.$router.push({
            path: "/login-tech"
          });
        } else if (!isNaN(this.search)) {
          currentQuery.product_codes = this.search.replace(/^0+/, "");
        } else {
          currentQuery.q = this.search;
          this.saveWord(currentQuery.q);
        }
      }

      if (currentQuery.q || currentQuery.product_codes) {
        AnalyticsService.search(this.search);
        this.$store.dispatch("category/resetFilters");
        this.$router.push({
          // path: "/search?q=:q&parent_category_id=:parent_category_id",
          path: "/search",
          name: "Search",
          query: currentQuery
        });

        this.$nextTick(() => {
          //const autocomplete = this.$refs.autocomplete;
          //autocomplete.blur();
          this.search = currentQuery.q;
        });
      }
    },
    openFastSearchDialog() {
      this.$emit("closeSearchModal", true);
      var defaultConfig = {
        toolbarColor: "secondary",
        fullscreen: true
      };
      let properties = {
        waitForResult: true,
        width: 750
      };
      var config = Object.assign({}, properties, defaultConfig);
      this.dialogInstance = this.$dialog.show(FastSearchMenu, config);
    }
  },
  beforeDestroy() {
    global.EventBus.$off("clearSearch");
    global.EventBus.$off("focusSearch");
  },
  mounted() {
    vm = this;
    global.EventBus.$on("clearSearch", () => {
      this.search = null;
      this.selected = null;
    });
    global.EventBus.$on("focusSearch", () => {
      setTimeout(() => {
        this.$refs.autocomplete.focus();
      }, 200);
    });
    if (this.focusOnOpen) {
      this.$refs.autocomplete.focus();
    }
  }
};
</script>
